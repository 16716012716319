.container{
    height: 70dvh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

@media(min-height: 709px){
    .container{
        height: 77dvh;
    }
}

@media(min-height: 939px){
    .container{
        height: 83dvh;
    }
}