
.footer span{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer, .footerHome, .footerAllPages {
  display: flex;
  gap: .5em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 1201;
  color: #757575;
  font-weight: 400;
  background-color: #EFEFEF; 
}

.footerHome{
  background-color: transparent;
  color: #fff;
}

.footerAllPages{
  color: #757575;
}